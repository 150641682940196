<template>
    <div>
        <div class="row mt-2 ml-2">
            <div class="col-md-12">
                <a href="javascript:void(0)" @click="serveSafeFood" class="d-block text-center">Click here for food handler, alcohol server, or ServSafe training</a>
            </div>
        </div>

        <modal :show.sync="serveSafeFoodModal" size="lg">
            <div class="row">
                <div class="col-md-12">
                    <div class="modal-content-wrapper customModal">
                        <img class="logo_img" src="/orla/logo.png"/>
                        <div>
                            <h4>See you later!</h4>
                            <p class="text-left">You are about to leave ORLA’s Train 321 Platform to go to ORLA’s main website and training page. Click YES to continue to the website. Click NO to go back to the ORLA’s Train 321 Platform</p>
                        </div>
                    </div>

                    <div class="modal-button-wrapper">

                        <button @click="serveSafeFoodActionButtons('no')" class="btn">No</button>
                        <button @click="serveSafeFoodActionButtons('yes')" class="btn">Yes</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
export default {
    data: function () {
        return {
            serveSafeFoodModal: false,
        };
    },
    methods: {
        serveSafeFood: function () {
            this.serveSafeFoodModal = !this.serveSafeFoodModal;
        },
        serveSafeFoodActionButtons: function (action) {
            this.serveSafeFood();
            if (action === 'yes') {
                window.open('https://www.oregonrla.org/training.html', '_blank');
            }
        },
    }
}
</script>
<style scoped>
.modal-content-wrapper{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.modal-content-wrapper img.logo_img{
    padding: 0px;
    width: 184px;
}

.modal-content-wrapper h4{
    margin: 0;
    margin-bottom: 7px;
    font-size: 22px;
    font-weight: bold;
}

.modal-content-wrapper p {
    font-size: 12px;
    font-weight: 400;
}

.modal-button-wrapper{
    text-align: center;
}
.modal-button-wrapper button:last-child{
    color: #fff;
    background-color: #b4d570;
    border-color: #b4d570;
    -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    padding-block: 6px;
}

.modal-button-wrapper button:first-child{
    color: #b4d570;
    border-color: #b4d570;
    -webkit-box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    padding-block: 6px;
}
</style>
